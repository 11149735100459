import * as React from 'react';
import { Link } from 'gatsby';
import Section, { TextAlign } from 'components/section';
import { Helmet } from 'react-helmet';

function NotFoundPage() {
  return (
    <Section textAlign={TextAlign.CENTER}>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <h1>Page not found</h1>
      <p>
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        we couldn&apos;t find what you were looking for.
      </p>
      {process.env.NODE_ENV === 'development' ? (
        <p>
          Try creating a page in <code>src/pages/</code>.
        </p>
      ) : null}
      <p>
        <Link to="/">Go home</Link>.
      </p>
    </Section>
  );
}

export default NotFoundPage;
